<template>
    <div class="mainSpyContainer">
        <Ozellikler></Ozellikler>
    </div>
</template>

<script>
    import Ozellikler from './Ozellikler';

    export default {
        components: {
            Ozellikler
        }
    }
</script>



